<template>
  <div class="contract">
    <v-breadcrumbs :items="items" class="bg-silver">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <h1 class="px-main text-heading--prm bg-white text-center">
      {{ $t("newsPage.contractList") }}
    </h1>

    <!-- ====================board contract======================== -->
    <div
      class="contract-box mx-auto bg-smoke"
      v-for="(list, i) in listContracts"
      :key="i"
    >
      <div class="item">
        <h4>
          {{ list.title }}
        </h4>
        <v-divider></v-divider>
        <v-row class="mt-2">
          <!-- ====================row1=================== -->

          <v-col cols="4">
            <div class="d-flex justify-space-between align-baseline">
              <p class="text-medium-szm">{{ list.contractYear }}</p>
              <div class="d-flex align-baseline justify-content-between">
                <h2>{{ list.numberYear }}</h2>
                <p class="text-medium-szm text-dark">{{ list.year }}</p>
              </div>
              <v-divider vertical></v-divider>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-space-between align-baseline">
              <div class="d-flex text-medium-szm">
                <p class="">{{ list.rightBasis }}</p>
                <p class="text-date">{{ list.date }}</p>
                <p class="">{{ list.to }}</p>
              </div>
              <div class="d-flex align-baseline">
                <h2>{{ list.name }}</h2>

                <div class="d-flex align-baseline">
                  <p class="text-large-szlx">{{ list.numberWeek }}</p>
                  <p class="text-medium-szm text-dark">{{ list.week }}</p>
                </div>
              </div>
              <v-divider vertical></v-divider>
            </div>
          </v-col>

          <v-col cols="4">
            <div class="text-end">
              <v-btn
                elevation="0"
                rounded
                @click="submitButton"
                class="btn text-medium-szm"
              >
                {{ list.btn }}
              </v-btn>
            </div>
            <div class="d-flex justify-space-between align-baseline">
              <p class="text-medium-szm">{{ list.status }}</p>
              <h2>{{ list.deposit }}</h2>
            </div>
          </v-col>
          <!-- ======================row2==================== -->

          <v-col cols="4">
            <div class="d-flex justify-space-between align-baseline">
              <div class="d-flex">
                <p class="text-medium-szm">{{ list.day }}{{ list.deadline }}</p>
                <div class="d-flex font-weight-bold text-medium-szm">
                  <p>{{ list.numberDeadLine }}</p>
                  <p>{{ list.point }}</p>
                </div>
              </div>
              <div class="d-flex align-baseline text-medium-szm">
                <p>{{ list.form }}</p>
                <p class="text-dark font-weight-bold">{{ list.dateStart }}</p>
              </div>
            </div>
          </v-col>

          <v-col cols="4">
            <div class="d-flex justify-space-between align-baseline">
              <div class="d-flex text-medium-szm">
                <p>{{ list.deadlineStart }}</p>
                <p class="">{{ list.end }}</p>
                <p class="text-date">{{ list.dateEnd }}</p>
              </div>
            </div>
          </v-col>

          <v-col cols="4">
            <div class="text-end">
              <v-btn
                elevation="0"
                rounded
                @click="submitButton"
                class="btn text-medium-szm"
              >
                {{ list.btn }}
              </v-btn>
            </div>
          </v-col>

          <!-- ==========================row3========================= -->
          <v-col cols="4">
            <div class="mobi">
              <h4>{{ list.title }}</h4>
              <v-divider></v-divider>
            </div>
            <div class="d-flex justify-space-between align-baseline">
              <p class="text-medium-szm">{{ list.contractYear }}</p>
              <div class="d-flex align-baseline">
                <h2>{{ list.numberYear }}</h2>
                <p class="text-medium-szm text-dark">{{ list.year }}</p>
              </div>
              <v-divider vertical></v-divider>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-space-between align-baseline">
              <div class="d-flex">
                <p class="text-medium-szm">{{ list.rightBasis }}</p>
                <p class="text-date">{{ list.date }}</p>
                <p>{{ list.to }}</p>
              </div>
              <div class="d-flex align-baseline">
                <h2>{{ list.name }}</h2>

                <p class="text-large-szlx">{{ list.numberWeek2 }}</p>
                <p class="text-medium-szm text-dark">{{ list.week }}</p>
              </div>
              <v-divider vertical></v-divider>
            </div>
          </v-col>

          <v-col cols="4">
            <div class="d-flex justify-space-between align-baseline">
              <p class="text-medium-szm">{{ list.status }}</p>
              <h2>{{ list.deposit }}</h2>
            </div>
          </v-col>
          <!-- =======================row4==================== -->

          <v-col cols="12">
            <div class="text-end">
              <v-btn
                elevation="0"
                rounded
                @click="submitButton"
                class="btn text-medium-szm"
              >
                {{ list.btn }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contract',
  data () {
    return {
      items: [
        {
          text: this.$t('title.main'),
          disabled: false,
          href: '/home'
        },
        {
          text: this.$t('newsPage.contractList'),
          disabled: true,
          href: ''
        }
      ],

      listContracts: [
        {
          title: this.$t('contract.title.title1'),
          contractYear: this.$t('contract.contractYear.contractYear1'),
          numberYear: '2021',
          year: this.$t('contract.year.year1'),
          rightBasis: this.$t('contract.rightBasis.rightBasis1'),
          name: '京都',
          numberWeek: '46',
          week: this.$t('contract.contractYear.week'),
          btn: this.$t('contract.btn')
        },
        {
          title: this.$t('contract.title.title2'),
          contractYear: this.$t('contract.contractYear.contractYear2'),
          numberYear: '101',
          year: this.$t('contract.year.point'),
          rightBasis: this.$t('contract.rightBasis.rightBasis2'),
          date: '2022/07/30',
          dateEnd: '2023/07/30',
          to: this.$t('contract.to'),
          deadline: this.$t('contract.deadline'),
          deadlineStart: this.$t('contract.deadlineStart'),
          numberDeadLine: '180',
          point: this.$t('contract.year.point'),
          btn: this.$t('contract.btn')
        },
        {
          title: this.$t('contract.title.title3'),
          contractYear: this.$t('contract.contractYear.contractYear1'),
          numberYear: '2021',
          year: this.$t('contract.year.year1'),
          rightBasis: this.$t('contract.rightBasis.rightBasis1'),
          name: '京都',
          day: this.$t('contract.day'),
          form: this.$t('contract.form'),
          dateStart: '2021/09/22',
          end: this.$t('contract.end'),
          dateEnd: '2021/10/14',
          btn: this.$t('contract.btn')
        },
        {
          title: this.$t('contract.title.title3'),
          contractYear: this.$t('contract.contractYear.contractYear1'),
          numberYear: '2021',
          year: this.$t('contract.year.year1'),
          rightBasis: this.$t('contract.rightBasis.rightBasis1'),
          name: '京都',
          day: this.$t('contract.day'),
          form: this.$t('contract.form'),
          dateStart: '2021/09/22',
          end: this.$t('contract.end'),
          dateEnd: '2021/10/14',
          btn: this.$t('contract.btn')
        },
        {
          title: this.$t('contract.title.title4'),
          contractYear: this.$t('contract.contractYear.contractYear1'),
          numberYear: '2021',
          year: this.$t('contract.year.year1'),
          rightBasis: this.$t('contract.rightBasis.rightBasis1'),
          name: '京都',
          numberWeek: '46',
          numberWeek2: '40',
          week: this.$t('contract.contractYear.week'),
          status: 'ステータス',
          deposit: 'デポジット',
          btn: this.$t('contract.btn')
        }
      ]
    }
  },
  methods: {
    submitButton () {
      this.$router.push('/home/contract-detail')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./contract.scss";
</style>
